<template>
  <div v-if="traineeRole">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">Editing User Role: <small>{{ traineeRole.title }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"></danger-alert>

    <div class="form-group text-left">
      <label for="trainee_role_title">User Role Title</label>
      <input type="text" class="form-control" id="trainee_role_title" v-model="traineeRole.title" placeholder="Title">
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <router-link :to="{ name: 'TraineeRoleShow', params: { id: this.$route.params.id } }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="updateTraineeRole" type="button" class="btn btn-success">Update User Role</button>
      </div>
    </div>

    <br>

  </div>
  <loading-overlay v-else></loading-overlay>
</template>
<script>
import { TraineeRoleService } from '@/common/services/trainee_role.service';
import DangerAlert from '@/common/components/danger-alert.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'TraineeRoleEdit',
  components: {
    DangerAlert,
    LoadingOverlay,
  },
  data() {
    return {
      traineeRole: null,
      errors: [],
    };
  },
  created() {
    TraineeRoleService.get(this.$route.params.id)
      .then(({ data }) => {
        this.traineeRole = data;
      });
  },
  methods: {
    updateTraineeRole() {
      TraineeRoleService.update(this.$route.params.id, this.traineeRole)
        .then(() => {
          this.$router.push({ name: 'TraineeRoleShow', params: { id: this.$route.params.id } });
        }).catch((err) => {
          // Scroll to the top of this view so user's can see errors
          window.scrollTo(0, document.getElementById('top').offsetTop);
          this.errors = err.response.data.error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/trainee_roles";
</style>
